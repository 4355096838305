import { endpoints } from "@app/config/endpoints";
import { saveData } from "@app/lib/api-utils";
import moment from "moment-timezone";

const url = endpoints.transaction;
export const saveTransaction = (transaction: ITransaction) => saveData({ url }, transaction);

export const refineTransactionFromApi = (d: ITransaction) => {
    d.created_at = moment(d.created_at).tz("UTC").format("LLL");
    return d;
};

export const dummyTransaction = (transaction?: ITransaction): ITransaction => {
    return {
        method: "",
        amount: 0,
        currency: "AZN",
        note: "",
        created_at: Date.now().toString(),
        updated_at: Date.now().toString(),
        brand: {},
        ...transaction,
    } as ITransaction;
};
