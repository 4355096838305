import { refineTransactionFromApi } from "@app/actions/transaction-actions";
import { DataProvider } from "@app/components/DataTable/DataProvider";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { TransactionStatusTag } from "@app/components/misc/TransactionStatusTag";
import { endpoints } from "@app/config/endpoints";
import { sessionStore } from "@app/stores/SessionStore";
import { TransactionStatus } from "@config/enums";
import { TransactionForm } from "@pages/transactions/TransactionForm/TransactionForm";
import { transactionFormStore } from "@pages/transactions/TransactionForm/TransactionFormStore";
import * as React from "react";

export const transactionTableStore = new DataTableStore<ITransaction>({
    dataProvider: new DataProvider({
        url: endpoints.transaction,
        sort: ["created_at", -1],
        refiner: refineTransactionFromApi,
        with: ["brand.partner"],
        filters: [
            {
                name: "method",
                filter: ["method", "!=", "REMAIN"],
            },
            // {
            //     name: "start-date",
            //     filter: [
            //         "created_at",
            //         ">=",
            //         format(startOfMonth(new Date()), "yyyy-MM-dd"),
            //     ],
            // },
        ],
    }),
    formStore: () => transactionFormStore,
    form: TransactionForm,
    columnsManager: {
        hiddenColumns: ["id"],
    },
    filters: [
        {
            name: "status",
            type: "dropdown",
            defaultValue: TransactionStatus.SUCCESS,
            values: {
                [TransactionStatus.SUCCESS]: "Success",
                [TransactionStatus.FAILED]: "Failed",
                [TransactionStatus.PENDING]: "Pending",
            },
        },
        {
            name: "brand_name",
            type: "input",
        },
        {
            name: "partnerId",
            label: "Partner",
            type: "dropdown-search-key-value",
            placeholder: "Partner",
            endpoint: "partners",
            defaultValue: "___",
        },
        {
            type: "daterange",
            label: "Date range",
            name: "dateFilter",
        },
    ],
    columns: [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 150,
        },
        {
            title: "Brand",
            dataIndex: "brand.title",
            key: "brand.name",
            align: "center",
            width: 150,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            align: "center",
            width: 150,
        },
        ...(sessionStore.user?.partner?.parent_id
            ? []
            : [
                  {
                      title: "Dealer",
                      dataIndex: "brand.partner.name",
                      key: "dealer",
                      align: "center",
                      width: 150,
                  } as const,
              ]),
        {
            title: "Type",
            dataIndex: "method",
            key: "method",
            align: "center",
            width: 150,
        },
        {
            title: "Commission",
            dataIndex: "commission",
            key: "commission",
            align: "center",
            width: 150,
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: 150,
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note",
            align: "center",
            width: 150,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 150,
            render: status => <TransactionStatusTag status={status} />,
        },
    ],
});
