export const endpoints = {
    user: "/user",
    authUser: "/auth/user",
    brand: "/brand",
    brandLogin: "/brand/:id/login",
    brandTerminals: "/brand/:id/terminal",
    client: "/client",
    transaction: "/transaction",
    package: "/package",
    module: "/module",
    role: "/role",
    terminals: "/terminals",
    lastLogin: "lastLogin",
    partners: "/partners",
};
