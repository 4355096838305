export const API_URL = getAPI_URL();
export const isDev = process.env.NODE_ENV === "development";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const IS_MOBILE = window.innerWidth < 800;
function getAPI_URL(): string {
    if (location.hostname === "p.clopos.test") {
        return "http://papi.clopos.test/";
    }
    return `https://papi.clopos.com/`;
}

// https://storage.googleapis.com/clopos-log/demo/receipt.json
// https://storage.googleapis.com/clopos-log/demo/customer.json
// https://storage.googleapis.com/clopos-log/demo/request.json

export const Actions = {
    clearFinance: "Clear Finance",
    clearInventory: "Clear Inventory",
    clearSale: "Clear Sale",
    ImportEmenu: "Import Emenu",
    fillMenu: "Clone Menu",
};
