import { observable, action, toJS } from "mobx";
import { depot } from "@cloposcom/libs";
import { ajax } from "@app/lib/ajax";
import { message } from "antd";
import { attempt } from "@app/lib/utils";
import { refineUserFromApi, getUserById } from "@app/actions/user-actions";
import { loadAppData } from "./AppStore";

class SessionStore {
    @observable public payload = {
        email: "",
        password: "",
    };

    @observable public user: IUser;

    @observable public loading = false;

    @observable public isLoggedIn = false;
    @observable public token: string = "";
    @observable public email: string = "";
    @observable public userId: number;
    @observable public role: IRoleResponse;
}

export const sessionStore = new SessionStore();

export const initSessionStore = action(async () => {
    window.__.sessionStore = sessionStore;
    if (depot.getItem("isLoggedIn")) {
        await loadSessionData();
    }
});

export const attemptLogin = action(async () => {
    await attempt(
        async () => {
            sessionStore.loading = true;
            const resp = await ajax.post({
                url: "/auth/login",
                data: sessionStore.payload,
            });
            if (resp.success) {
                message.success("Login successful");
                ajax.setToken(resp.data.api_token);
                await loadAppData();
                sessionStore.token = resp.data.api_token;
                sessionStore.email = resp.data.email;
                sessionStore.userId = resp.data.id;
                sessionStore.role = resp.data.roles?.[0] || null;
                sessionStore.user = refineUserFromApi(resp.data);
                sessionStore.isLoggedIn = true;
                persistSessionData();
                window.location.href = "/";
            }
        },
        undefined,
        () => {
            sessionStore.loading = false;
        }
    );
});

const persistSessionData = action(() => {
    const fields = ["isLoggedIn", "token", "email", "userId", "role"];
    fields.forEach((field) =>
        depot.setItem(field, (sessionStore as any)[field])
    );
    // depot.setItem("userId", sessionStore.user.id);
});

const loadSessionData = action(async () => {
    const fields = ["isLoggedIn", "token", "email", "role"];
    fields.forEach(
        (field) => ((sessionStore as any)[field] = depot.getItem(field))
    );
    ajax.setToken(sessionStore.token);
    await loadUser();
});

export const endSession = action(() => {
    sessionStore.isLoggedIn = false;
    sessionStore.token = "";
    sessionStore.email = "";
    sessionStore.userId = 0;
    persistSessionData();
});

export const loadUser = action(async () => {
    const user = await getUserById();
    sessionStore.user = user;
});
