import { refineUserFromApi } from "@app/actions/user-actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import { Button, Popconfirm, Input } from "antd";
import { partial } from "lodash";
import * as React from "react";
import { userFormStore } from "../UserForm/UserFormStore";
import { editButton, deleteButton } from "@app/components/DataTable/actions";
import { UserForm } from "../UserForm/UserForm";
import { observable } from "mobx";
import { DataProvider } from "@app/components/DataTable/DataProvider";

const state = observable({
    searchText: "",
});

const handleSearch = (field: string, selectedKeys: any, confirm: any) => () => {
    confirm();
    state.searchText = selectedKeys[0];
    userTableStore.dataProvider.addFilter([field, selectedKeys[0]], field);
};

const handleReset = (field: string, clearFilters: any) => () => {
    clearFilters();
    state.searchText = "";
    userTableStore.dataProvider.removeFilter(field);
};

export const userTableStore = new DataTableStore<IUser>({
    dataProvider: new DataProvider({
        url: endpoints.user,
        with: ["roles"],
        refiner: refineUserFromApi,
    }),

    formStore: () => userFormStore,
    form: UserForm,
    columnsManager: {
        hiddenColumns: ["created_at"],
    },
    columns: [
        {
            title: "E-mail",
            dataIndex: "email",
            key: "email",
            align: "center",
            width: 300,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: any) => {
                return (
                    <div
                        style={{
                            padding: 10,
                            background: "#fff",
                            boxShadow: "0 0 3px #666",
                            borderRadius: 5,
                        }}
                    >
                        <Input
                            // ref={ele => this.searchInput = ele}
                            placeholder="Search e-mail"
                            value={selectedKeys[0]}
                            onChange={(e) =>
                                setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                )
                            }
                            onPressEnter={handleSearch(
                                "email",
                                selectedKeys,
                                confirm
                            )}
                            style={{ marginBottom: 5 }}
                        />
                        <Button
                            type="primary"
                            onClick={handleSearch(
                                "email",
                                selectedKeys,
                                confirm
                            )}
                            style={{ marginRight: 5 }}
                        >
                            Search
                        </Button>
                        <Button onClick={handleReset("email", clearFilters)}>
                            Reset
                        </Button>
                    </div>
                );
            },
        },
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
            align: "center",
            width: 300,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: any) => {
                return (
                    <div
                        style={{
                            padding: 10,
                            background: "#fff",
                            boxShadow: "0 0 3px #666",
                            borderRadius: 5,
                        }}
                    >
                        <Input
                            // ref={ele => this.searchInput = ele}
                            placeholder="Search username"
                            value={selectedKeys[0]}
                            onChange={(e) =>
                                setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                )
                            }
                            onPressEnter={handleSearch(
                                "username",
                                selectedKeys,
                                confirm
                            )}
                            style={{ marginBottom: 5 }}
                        />
                        <Button
                            type="primary"
                            onClick={handleSearch(
                                "username",
                                selectedKeys,
                                confirm
                            )}
                            style={{ marginRight: 5 }}
                        >
                            Search
                        </Button>
                        <Button onClick={handleReset("username", clearFilters)}>
                            Reset
                        </Button>
                    </div>
                );
            },
        },
        {
            title: "Role",
            dataIndex: "role.title",
            key: "role",
            align: "center",
            width: 300,
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: 300,
        },
        {
            title: "Actions",
            key: "actions",
            width: 200,
            render: actions,
        },
    ],
});

const editBtn = editButton(userTableStore);
const deleteBtn = deleteButton(userTableStore);

function actions(data: any): JSX.Element {
    return <>{editBtn(data)}</>;
}
