import { attempt } from "@app/lib/utils";
import { ajax } from "@lib/ajax";

let sellers: ISeller[] = [];

export function loadSellers(): Promise<void> {
    return attempt(async () => {
        const resp = await ajax.get({
            url: "https://headless.clopos.com/api/9cdeed3c301a4de695b189168fea94b3/sellers",
            ignoreSuccess: true,
        });

        sellers = resp as unknown as ISeller[];
    });
}

export const getAllSellers = (): ISeller[] => sellers;

export function getSellerById(id: number): ISeller | undefined {
    return sellers?.find(r => r.id === id);
}
