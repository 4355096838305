import { RoleLevel } from "@app/config/enums";
import { loadPackages } from "@app/data/packages";
import { loadRoles } from "@app/data/roles";
import { loadSellers } from "@app/data/sellers";
import { initSessionStore, sessionStore } from "@app/stores/SessionStore";
import { depot } from "@cloposcom/libs";
import { createBrowserHistory } from "history";
import { action, computed, observable } from "mobx";

export class AppStore {
    @observable public isReady = false;

    @computed public get path(): string[] {
        return [location.pathname];
    }

    public history = createBrowserHistory();
}

export const initApp = action(async () => {
    depot.setPrefix("admin_");
    try {
        await initSessionStore();
        if (sessionStore.isLoggedIn) {
            await loadAppData();
        }
    } catch (e) {
        console.log(e);
    }
    appStore.isReady = true;
});

export const loadAppData = action(async (): Promise<void> => {
    if (sessionStore.role?.level >= RoleLevel.ADMIN) await loadRoles();

    await loadSellers();
    await loadPackages();
});

export const appStore = new AppStore();
