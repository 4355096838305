import { getPackage } from "@app/actions/brand-actions";
import { deleteButton, editButton, importButton } from "@app/components/DataTable/actions";
import { DataProvider } from "@app/components/DataTable/DataProvider";
import { DataTableStore, IDataTableFilters } from "@app/components/DataTable/DataTableStore";
import { BrandStatusTag } from "@app/components/misc/BrandStatusTag";
import { endpoints } from "@app/config/endpoints";
import { BrandStageStatus, BrandStatus, RoleLevel } from "@app/config/enums";
import { BrandStage, BrandStageStatusOptions, BrandType } from "@app/data/brand-stages";
import { getAllSellers, getSellerById } from "@app/data/sellers";
import { CountriesAll } from "@app/lib/utils";
import { sessionStore } from "@app/stores/SessionStore";
import { Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { BrandForm } from "../BrandForm/BrandForm";
import { brandFormStore } from "../BrandForm/BrandFormStore";
import { LoginButton } from "./LoginButton";

// const isAZPartner = localStorage.getItem("admin_partnerId") === "1";

const filtersColumns: IDataTableFilters = [
    {
        name: "status",
        type: "dropdown",
        values: {
            [BrandStatus.ACTIVE]: "Active",
            [BrandStatus.FREEZE]: "Freeze",
            [BrandStatus.EXPIRED]: "Expired",
            [BrandStatus.CLOSE]: "Close",
            [BrandStatus.PENDING]: "Pending",
            [BrandStatus.CREATING]: "Creating",
            [BrandStatus.DELETE]: "Deleted",
            [BrandStatus.DELETING]: "Deleting",
            [BrandStatus.OUT_OF_SERVICE]: "Out of Service",
            [BrandStatus.UPDATE]: "Update",
        },
        defaultValue: BrandStatus.ACTIVE,
    },
    {
        name: "stage",
        label: "Stage",
        type: "dropdown",
        values: {
            [BrandStage.ALPHA]: "Alpha",
            [BrandStage.BETA]: "Beta",
            [BrandStage.RC]: "RC",
            [BrandStage.PROD]: "Prod",
        },
        defaultValue: "___",
    },
    {
        name: "stage_status",
        label: "Stage Status",
        type: "dropdown",
        values: {
            [BrandStageStatus.DEFAULT]: "Default",
            [BrandStageStatus.TRIAL]: "Trail",
            [BrandStageStatus.TEST]: "Test",
        },
        defaultValue: "___",
    },
    {
        name: "package_id",
        label: "Package",
        type: "dropdown-multi",
        placeholder: "Package",
        endpoint: "package",
        defaultValue: "___",
    },
    {
        name: "package_id",
        label: "Module",
        type: "dropdown-multi",
        placeholder: "Module",
        endpoint: "module",
        defaultValue: "___",
    },
    {
        name: "seller_id",
        label: "Seller",
        type: "dropdown-multi",
        placeholder: "Seller",
        endpoint: "sellers",
        defaultValue: "___",
    },
    {
        name: "email",
        label: "E-mail",
        type: "dropdown-search",
        endpoint: "client",
        values: {},
        defaultValue: "___",
    },
    {
        name: "name",
        type: "input",
    },
];

const tableColumns: Array<ColumnProps<any>> = [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        align: "center",
        width: 50,
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "left",
        width: 150,
        render: (name, brand: IBrand) => (
            <div>
                <div>{name}</div>
                <div>{brand.title}</div>
            </div>
        ),
    },
    {
        title: "Stage",
        dataIndex: "stage",
        key: "stage",
        align: "center",
        width: 100,
    },
    {
        title: "Package",
        dataIndex: "subscription",
        key: "package",
        align: "center",
        width: 100,
        render: (__, brand) => {
            const p = getPackage(brand);
            const country = CountriesAll.find(cA => cA.value === brand.country);

            return (
                <div>
                    <div>
                        {p.title} {brand.subscription!.trial ? "(trial)" : ""}
                    </div>
                    <div>{brand.subscription!.period_price + " " + country?.currency}</div>
                </div>
            );
        },
    },
    {
        title: "Expire",
        dataIndex: "subscription.expired_at",
        key: "expired_at",
        align: "center",
        width: 130,
        render: (d, b) => {
            const m = moment(d);
            const diff = m.diff(new Date(), "day");

            return <Tag color={diff < 0 ? "red" : diff < 3 ? "orange" : "green"}>{m.fromNow()}</Tag>;
        },
    },
    {
        title: "Period",
        dataIndex: "subscription",
        key: "period",
        align: "center",
        width: 100,
        render: (__, brand) => {
            return brand?.subscription!?.monthly_period + " months";
        },
    },
    {
        title: "Dealer/Partner",
        dataIndex: "partner.name",
        key: "partner_id",
        align: "center",
        width: 100,
    },
    {
        title: "Client",
        dataIndex: "client",
        key: "client",
        align: "left",
        width: 150,
        render: (client: IClient) => (
            <div>
                <div>
                    {client?.first_name} {client?.last_name}
                </div>
                <div>
                    <a target="popup" href={`mailto:${client?.email}`}>
                        {client?.email}
                    </a>
                </div>
            </div>
        ),
    },
    {
        title: "Note",
        dataIndex: "note",
        key: "note",
        align: "left",
        width: 100,
    },
    {
        title: "Leaving reason",
        dataIndex: "left_reason_note",
        key: "left_reason_note",
        align: "left",
        width: 100,
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        render: (__, brand) => (
            <div>
                <BrandStatusTag status={brand.status} /> <br />
                <Tag style={{ marginTop: 3 }}>{BrandStageStatusOptions[brand.stage_status]}</Tag>
            </div>
        ),
    },
    {
        title: "Seller",
        dataIndex: "seller_id",
        key: "seller_id",
        align: "center",
        width: 200,
        render: id => {
            return getSellerById(id)?.full_name || "-";
        },
    },
    {
        title: "Venues",
        dataIndex: "venues_count",
        key: "venues_count",
        align: "center",
        width: 100,
    },
    {
        title: "Country",
        dataIndex: "country",
        key: "country",
        align: "center",
        width: 100,
    },
    {
        title: "Created at",
        dataIndex: "created_at",
        key: "created_at",
        align: "center",
        width: 100,
        render: str => {
            const parts = str.split(" ");
            return (
                <div>
                    {parts[0]} <br /> {parts[1]}
                </div>
            );
        },
    },
    {
        title: "Actions",
        key: "actions",
        width: 200,
        render: actions,
    },
];

export const brandTableStore = new DataTableStore<IBrand>({
    dataProvider: new DataProvider({
        url: endpoints.brand,
        with: ["client", "subscription.packages", "partner"],
        refiner: (d: any) => {
            const res = d;
            res.type = d.type === 1 || d.type === BrandType.RESTAURANT ? BrandType.RESTAURANT : BrandType.SHOP;
            return res;
        },
        filters: [
            {
                name: "withCount",
                filter: ["withCount", "venues"],
            },
        ],
        sort: ["id", "-1"],
    }),
    // refine: refineUserFromApi,
    formStore: () => brandFormStore,
    form: BrandForm,
    columnsManager: {
        hiddenColumns: ["created_at"],
    },
    filters: filtersColumns,
    columns: tableColumns,
});

const editBtn = editButton(brandTableStore);
export const deleteBtn = deleteButton(brandTableStore);
export const importMenuBtn = importButton(brandTableStore);

function actions(data: IBrand): JSX.Element {
    return (
        <>
            {editBtn(data)}
            {/* only admins and owners can login */}
            {sessionStore.user.role?.level! >= RoleLevel.ADMIN ? <LoginButton id={data.id!} /> : null}
        </>
    );
}
