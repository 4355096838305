import { ajax } from "@app/lib/ajax";
import { defaultRole } from "@app/data/roles";
import { get } from "lodash";
import { saveData, deleteData } from "@app/lib/api-utils";
import { endpoints } from "@app/config/endpoints";

const url = endpoints.user;
export const saveUser = (user: IUser) => saveData({ url }, user);

export const deleteUser = (user: IUser | number) => deleteData(url, user);

export const refineUserFromApi = (d: IUser) => {
    d.role = get(d, "roles[0]");
    d.token = get(d, "api_token");
    return d;
};

export const getUserById = async (): Promise<IUser> => {
    const resp = await ajax.get({ url: endpoints.authUser, params: { with: ["roles", "partner"] } });
    return refineUserFromApi(resp.data);
};

export const dummyUser = (user?: IUser): IUser => {
    return {
        email: "",
        username: "",
        password: "",
        token: "",
        created_at: Date.now().toString(),
        updated_at: Date.now().toString(),
        role: defaultRole(),
        ...user
    };
};
