import { dummyClient, refineClientFromApi } from "@app/actions/client-actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { clientTableStore } from "../ClientsPage/clientTableStore";
import { action, observable } from "mobx";
import { ajax } from "@app/lib/ajax";
import { sessionStore } from "@app/stores/SessionStore";

export class ClientFormStore extends FormDrawerStore<IClient> {
    public autofocus = "first_name";
    public dataName = "client";
    public dummyDataFn = dummyClient;
    public apiUrl = endpoints.client;
    @observable public _data = dummyClient();

    @observable
    public partners: IPartner[] = [];

    public afterSaveCallback?: (d: IClient) => void;

    beforeShow = async () => {
        await this.loadDealers();
    };

    get tableStore(): DataTableStore {
        return clientTableStore;
    }

    set tableStore(s: DataTableStore) {}

    public async loadDealers(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.partners,
            params: {
                filters: [
                    ["type", "=", "DEALER"],
                    ["parent_id", "=", sessionStore.user.partner_id],
                ],
                sort: ["id", "=", -1],
            },
        });
        this.partners = [sessionStore.user.partner, ...resp.data];
    }

    public afterSave = (d: IClient) => {
        d = refineClientFromApi(d);
        clientTableStore.dataProvider.updateLocalCopyOfData(d);
        if (this.afterSaveCallback) {
            this.afterSaveCallback(d);
            this.afterSaveCallback = undefined;
        }
    };

    @action
    public setPartnerId = (id: number) => {
        this.data.partner_id = id;
    };
}

export const clientFormStore = new ClientFormStore();
