import { ajax } from "../lib/ajax";
import { attempt } from "@app/lib/utils";
import { clone } from "lodash";
import { endpoints } from "@app/config/endpoints";

let _roles: IRole[] = [];
const DEFAULT_ROLE_ID = 3;

// TODO: call only if authorised, and right after successful login
export function loadRoles(): Promise<void> {
    return attempt(async () => {
        const resp = await ajax.get({url: endpoints.role});
        _roles = resp.data;
    });
}

export function roles(): IRole[] {
    return _roles;
}

export function roleById(id: number): IRole | undefined {
    return _roles.find(r => r.id === id);
}

export function roleNames(): string[] {
    return _roles.map(role => role.name);
}

export const defaultRole = () => clone(roleById(DEFAULT_ROLE_ID));
